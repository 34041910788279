import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  DividedSection,
  Badge,
  Title,
  Text,
  Button
} from "gatsby-theme-material-foundry"
import {
  Container,
  Box,
  Divider,
  Grid
} from "@material-ui/core"
import SEO from "../components/SEO/seo"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../gatsby-theme-material-foundry/theme"
import ShareList from "../components/sharelist"

function NewsTemplate(props) {
  const {
    slug,
    dato,
    tekst,
    tittel,
    coverUrl,
    forfatter,
    link,
    id,
  } = props.data.nyheter
  const backupEventImg = props.data.eventImg.childImageSharp.fixed.src
  const splitText = tekst.split("\n")
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO
          postDescription={tekst}
          postTitle={tittel}
          slug={`nyheter/${slug}`}
          datePublished={dato}
          postImage={coverUrl ? `${coverUrl}&sz=w1000` : backupEventImg}
        />
        <DividedSection
          black
          height="500px"
          image={backupEventImg}
          backgroundBlendMode="multiply"
          backgroundColor={coverUrl ? "#555" : "white"}
        >
          <Container maxWidth="md">
            <Box pt={12}>
              <Text variant="subheader">
                <Badge color="primary">Nyhetssak</Badge>
              </Text>
              <Title variant="h2" gutterBottom>
                {tittel}
              </Title>
              <Text variant="h5">{dato}</Text>
            </Box>
          </Container>
        </DividedSection>
        <DividedSection minHeight="50vh">
          <Container maxWidth="md">
            <Box pb={8} style={{ marginTop: "-50px" }}>
              <img
                src={`${coverUrl}&sz=w1500`}
                alt={`newsimage-${id}`}
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "cover",
                  objectPosition: "100% 50%",
                  boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}
              />
              <Box py={4}>
                {splitText.map((p, i) =>
                  p.length > 0 ? (
                    <Box py={1} key={i}>
                      {" "}
                      <Text
                        variant="paragraph"
                        style={{ fontSize: "16px", lineHeight: 1.5 }}
                        gutterBottom
                      >
                        {p}
                      </Text>{" "}
                    </Box>
                  ) : null
                )}
                {link && <Button color="black" to={link}>les mer her</Button>}
              </Box>

              <Divider />
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box py={2}>
                    Forfatter: {forfatter} | dato: {dato}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ShareList url={props.location.href} />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </DividedSection>
      </Layout>
    </ThemeProvider>
  )
}

export default NewsTemplate

export const ItemPageQuery = graphql`
  query SingleNews($itemId: String!) {
    nyheter(id: { eq: $itemId }) {
      slug
      dato(locale: "nb", formatString: "dddd, Do MMMM YYYY")
      tekst
      tittel
      coverUrl
      campus
      blurb
      forfatter
      id
      link
    }

    eventImg: file(relativePath: { eq: "event_cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
